<template>
  <div class="page-content">
    <div style="background-color: #FFFFFF;padding: 40px 60px;margin-top: 25px;margin-bottom: 25px;">
      <h3 style="text-align: center">北京2023年1-4月规模以上第三产业法人单位主要经济指标</h3>
      <h6 style="text-align: right">数据来源：北京市统计局</h6>
      <el-table
          :data="data"
          style="width: 100%;margin-bottom: 20px;"
          row-key="name"
          border
          :expand-row-keys="['按隶属关系分组','按注册登记类型分组','按行业分组']"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column
            prop="name"
            label="项目"
            width="270">
        </el-table-column>
        <el-table-column
            prop="dws"
            label="单位数(个)"
            width="90" align="right">
        </el-table-column>
        <el-table-column label="收入合计(亿元)" align="center">
          <el-table-column
              prop="srhj"
              label="1-4月" align="right">
          </el-table-column>
          <el-table-column
              prop="srhjtb"
              label="同步增长(%)" align="right">
          </el-table-column>
        </el-table-column>
        <el-table-column label="企业营业收入(亿元)" align="center">
          <el-table-column
              prop="yysr"
              label="1-4月" align="right">
          </el-table-column>
          <el-table-column
              prop="yysrtb"
              label="同步增长(%)" align="right">
          </el-table-column>
        </el-table-column>
        <el-table-column label="利润总额(亿元)" align="center">
          <el-table-column
              prop="lrze"
              label="1-4月" align="right">
          </el-table-column>
          <el-table-column
              prop="lrzetb"
              label="同步增长(%)" align="right">
          </el-table-column>
        </el-table-column>
        <el-table-column label="从业人员平均人数(万人)" align="center">
          <el-table-column
              prop="emp"
              label="1-4月" align="right">
          </el-table-column>
          <el-table-column
              prop="emptb"
              label="同步增长(%)" align="right">
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "MacroEconomy",
  data(){
    return {
      data:[
        {
          "name": "合         计",
          "level": 0,
          "dws": "39810",
          "srhj": "52698.2",
          "srhjtb": "2.6",
          "yysr": "50605.6",
          "yysrtb": "3.4",
          "lrze": "4577.9",
          "lrzetb": "-43.5",
          "emp": "649.8",
          "emptb": "-4.0"
        },
        {
          "name": "按隶属关系分组",
          "level": 0,
          "dws": "39810",
          "srhj": "52698.2",
          "srhjtb": "2.6",
          "yysr": "50605.6",
          "yysrtb": "3.4",
          "lrze": "4577.9",
          "lrzetb": "-43.5",
          "emp": "649.8",
          "emptb": "-4.0",
          "children": [
            {
              "name": "中央",
              "level": 1,
              "dws": "3419",
              "srhj": "17111.8",
              "srhjtb": "-0.6",
              "yysr": "16214.8",
              "yysrtb": "1.5",
              "lrze": "1999.7",
              "lrzetb": "-77.0",
              "emp": "127.3",
              "emptb": "1.9"
            },
            {
              "name": "地方",
              "level": 1,
              "dws": "36391",
              "srhj": "35586.4",
              "srhjtb": "4.2",
              "yysr": "34390.8",
              "yysrtb": "4.3",
              "lrze": "2578.2",
              "lrzetb": "75.7",
              "emp": "522.5",
              "emptb": "-5.4"
            }
          ]
        },
        {
          "name": "按注册登记类型分组",
          "level": 0,
          "dws": "39810",
          "srhj": "52698.2",
          "srhjtb": "2.6",
          "yysr": "50605.6",
          "yysrtb": "3.4",
          "lrze": "4577.9",
          "lrzetb": "-43.5",
          "emp": "649.8",
          "emptb": "-4.0",
          "children": [
            {
              "name": "内资",
              "level": 1,
              "dws": "36203",
              "srhj": "38448.0",
              "srhjtb": "1.7",
              "yysr": "36356.1",
              "yysrtb": "2.7",
              "lrze": "3423.9",
              "lrzetb": "-55.7",
              "emp": "530.4",
              "emptb": "-3.9",
              "children": [
                {
                  "name": "国有",
                  "level": 2,
                  "dws": "3427",
                  "srhj": "2571.7",
                  "srhjtb": "-8.4",
                  "yysr": "588.7",
                  "yysrtb": "14.5",
                  "lrze": "82.4",
                  "lrzetb": "-97.6",
                  "emp": "96.1",
                  "emptb": "0.2"
                },
                {
                  "name": "集体",
                  "level": 2,
                  "dws": "287",
                  "srhj": "85.3",
                  "srhjtb": "10.4",
                  "yysr": "77.2",
                  "yysrtb": "9.2",
                  "lrze": "-3.6",
                  "lrzetb": "-",
                  "emp": "2.6",
                  "emptb": "-5.5"
                },
                {
                  "name": "股份合作",
                  "level": 2,
                  "dws": "264",
                  "srhj": "43.6",
                  "srhjtb": "-1.0",
                  "yysr": "43.6",
                  "yysrtb": "-1.0",
                  "lrze": "-5.4",
                  "lrzetb": "-",
                  "emp": "2.0",
                  "emptb": "-2.0"
                },
                {
                  "name": "联营企业",
                  "level": 2,
                  "dws": "15",
                  "srhj": "1.7",
                  "srhjtb": "-6.8",
                  "yysr": "1.7",
                  "yysrtb": "-6.8",
                  "lrze": "-0.4",
                  "lrzetb": "-",
                  "emp": "0.1",
                  "emptb": "-2.3"
                },
                {
                  "name": "有限责任公司",
                  "level": 2,
                  "dws": "13631",
                  "srhj": "23618.3",
                  "srhjtb": "5.3",
                  "yysr": "23618.0",
                  "yysrtb": "5.3",
                  "lrze": "2568.7",
                  "lrzetb": "81.4",
                  "emp": "219.1",
                  "emptb": "-6.2"
                },
                {
                  "name": "股份有限公司",
                  "level": 2,
                  "dws": "856",
                  "srhj": "6821.0",
                  "srhjtb": "-3.1",
                  "yysr": "6821.0",
                  "yysrtb": "-3.1",
                  "lrze": "660.4",
                  "lrzetb": "-58.2",
                  "emp": "66.2",
                  "emptb": "-0.7"
                },
                {
                  "name": "私营",
                  "level": 2,
                  "dws": "17466",
                  "srhj": "5178.8",
                  "srhjtb": "-1.2",
                  "yysr": "5176.7",
                  "yysrtb": "-1.2",
                  "lrze": "119.0",
                  "lrzetb": "-",
                  "emp": "139.0",
                  "emptb": "-4.4"
                },
                {
                  "name": "其他内资",
                  "level": 2,
                  "dws": "257",
                  "srhj": "127.5",
                  "srhjtb": "2.1",
                  "yysr": "29.0",
                  "yysrtb": "-12.0",
                  "lrze": "2.8",
                  "lrzetb": "-22.5",
                  "emp": "5.2",
                  "emptb": "1.4"
                }
              ]
            },
            {
              "name": "港澳台商投资",
              "level": 1,
              "dws": "1378",
              "srhj": "6922.7",
              "srhjtb": "16.0",
              "yysr": "6922.7",
              "yysrtb": "16.0",
              "lrze": "575.4",
              "lrzetb": "86.7",
              "emp": "59.4",
              "emptb": "-6.0"
            },
            {
              "name": "外商投资",
              "level": 1,
              "dws": "2229",
              "srhj": "7327.5",
              "srhjtb": "-3.3",
              "yysr": "7326.8",
              "yysrtb": "-3.3",
              "lrze": "578.6",
              "lrzetb": "27.1",
              "emp": "60.1",
              "emptb": "-3.7"
            }
          ]
        },
        {
          "name": "按行业分组",
          "level": 0,
          "dws": "39810",
          "srhj": "52698.2",
          "srhjtb": "2.6",
          "yysr": "50605.6",
          "yysrtb": "3.4",
          "lrze": "4577.9",
          "lrzetb": "-43.5",
          "emp": "649.8",
          "emptb": "-4.0",
          "children": [
            {
              "name": "批发和零售业",
              "level": 1,
              "dws": "10739",
              "srhj": "24304.6",
              "srhjtb": "0.8",
              "yysr": "24304.6",
              "yysrtb": "0.8",
              "lrze": "381.0",
              "lrzetb": "-20.0",
              "emp": "62.6",
              "emptb": "-6.5",
              "children": [
                {
                  "name": "批发业",
                  "level": 2,
                  "dws": "8165",
                  "srhj": "21359.7",
                  "srhjtb": "1.7",
                  "yysr": "21359.7",
                  "yysrtb": "1.7",
                  "lrze": "367.7",
                  "lrzetb": "-23.0",
                  "emp": "41.6",
                  "emptb": "-4.3"
                },
                {
                  "name": "零售业",
                  "level": 2,
                  "dws": "2574",
                  "srhj": "2944.9",
                  "srhjtb": "-4.9",
                  "yysr": "2944.9",
                  "yysrtb": "-4.9",
                  "lrze": "13.3",
                  "lrzetb": "-",
                  "emp": "21.0",
                  "emptb": "-10.6"
                }
              ]
            },
            {
              "name": "交通运输、仓储和邮政业",
              "level": 1,
              "dws": "817",
              "srhj": "2915.7",
              "srhjtb": "11.6",
              "yysr": "2915.7",
              "yysrtb": "11.6",
              "lrze": "260.6",
              "lrzetb": "77.9",
              "emp": "51.4",
              "emptb": "-4.9",
              "children": [
                {
                  "name": "铁路运输业",
                  "level": 2,
                  "dws": "17",
                  "srhj": "607.9",
                  "srhjtb": "43.2",
                  "yysr": "607.9",
                  "yysrtb": "43.2",
                  "lrze": "70.3",
                  "lrzetb": "-",
                  "emp": "9.5",
                  "emptb": "-2.5"
                },
                {
                  "name": "道路运输业",
                  "level": 2,
                  "dws": "327",
                  "srhj": "728.3",
                  "srhjtb": "11.5",
                  "yysr": "728.3",
                  "yysrtb": "11.5",
                  "lrze": "2.2",
                  "lrzetb": "-",
                  "emp": "19.5",
                  "emptb": "-9.0"
                },
                {
                  "name": "航空运输业",
                  "level": 2,
                  "dws": "34",
                  "srhj": "371.5",
                  "srhjtb": "42.8",
                  "yysr": "371.5",
                  "yysrtb": "42.8",
                  "lrze": "-43.4",
                  "lrzetb": "-",
                  "emp": "7.6",
                  "emptb": "-0.9"
                }
              ]
            },
            {
              "name": "住宿和餐饮业",
              "level": 1,
              "dws": "3308",
              "srhj": "419.4",
              "srhjtb": "23.3",
              "yysr": "419.4",
              "yysrtb": "23.3",
              "lrze": "18.3",
              "lrzetb": "-",
              "emp": "34.0",
              "emptb": "-3.3",
              "children": [
                {
                  "name": "住宿业",
                  "level": 2,
                  "dws": "1140",
                  "srhj": "117.7",
                  "srhjtb": "43.0",
                  "yysr": "117.7",
                  "yysrtb": "43.0",
                  "lrze": "6.5",
                  "lrzetb": "-",
                  "emp": "8.0",
                  "emptb": "-6.7"
                },
                {
                  "name": "餐饮业",
                  "level": 2,
                  "dws": "2168",
                  "srhj": "301.7",
                  "srhjtb": "17.0",
                  "yysr": "301.7",
                  "yysrtb": "17.0",
                  "lrze": "11.8",
                  "lrzetb": "-",
                  "emp": "26.1",
                  "emptb": "-2.2"
                }
              ]
            },
            {
              "name": "信息传输、软件和信息技术服务业",
              "level": 1,
              "dws": "4247",
              "srhj": "7588.7",
              "srhjtb": "11.4",
              "yysr": "7577.3",
              "yysrtb": "11.6",
              "lrze": "637.0",
              "lrzetb": "95.0",
              "emp": "114.9",
              "emptb": "-4.4",
              "children": [
                {
                  "name": "电信、广播电视和卫星传输服务",
                  "level": 2,
                  "dws": "248",
                  "srhj": "471.0",
                  "srhjtb": "1.9",
                  "yysr": "468.7",
                  "yysrtb": "2.4",
                  "lrze": "32.8",
                  "lrzetb": "-5.1",
                  "emp": "7.6",
                  "emptb": "-1.3"
                },
                {
                  "name": "互联网和相关服务",
                  "level": 2,
                  "dws": "767",
                  "srhj": "2579.0",
                  "srhjtb": "12.9",
                  "yysr": "2575.4",
                  "yysrtb": "13.1",
                  "lrze": "148.4",
                  "lrzetb": "-",
                  "emp": "18.3",
                  "emptb": "-12.8"
                },
                {
                  "name": "软件和信息技术服务业",
                  "level": 2,
                  "dws": "3232",
                  "srhj": "4538.7",
                  "srhjtb": "11.6",
                  "yysr": "4533.2",
                  "yysrtb": "11.7",
                  "lrze": "455.8",
                  "lrzetb": "53.4",
                  "emp": "89.0",
                  "emptb": "-2.7"
                }
              ]
            },
            {
              "name": "金融业",
              "level": 1,
              "dws": "2489",
              "srhj": "8251.1",
              "srhjtb": "1.9",
              "yysr": "8237.5",
              "yysrtb": "1.9",
              "lrze": "2690.9",
              "lrzetb": "-12.9",
              "emp": "59.3",
              "emptb": "-5.4"
            },
            {
              "name": "房地产业",
              "level": 1,
              "dws": "3268",
              "srhj": "1403.6",
              "srhjtb": "0.3",
              "yysr": "1401.2",
              "yysrtb": "0.3",
              "lrze": "114.2",
              "lrzetb": "66.4",
              "emp": "45.1",
              "emptb": "-6.0"
            },
            {
              "name": "租赁与商务服务业",
              "level": 1,
              "dws": "5227",
              "srhj": "2786.2",
              "srhjtb": "4.6",
              "yysr": "2734.2",
              "yysrtb": "4.7",
              "lrze": "194.3",
              "lrzetb": "-94.2",
              "emp": "100.9",
              "emptb": "-2.8",
              "children": [
                {
                  "name": "组织管理服务",
                  "level": 2,
                  "dws": "693",
                  "srhj": "627.6",
                  "srhjtb": "19.1",
                  "yysr": "600.5",
                  "yysrtb": "20.0",
                  "lrze": "132.5",
                  "lrzetb": "-96.0",
                  "emp": "12.1",
                  "emptb": "-0.2"
                },
                {
                  "name": "综合管理服务",
                  "level": 2,
                  "dws": "250",
                  "srhj": "90.0",
                  "srhjtb": "1.7",
                  "yysr": "89.2",
                  "yysrtb": "2.0",
                  "lrze": "19.6",
                  "lrzetb": "94.8",
                  "emp": "2.3",
                  "emptb": "-3.0"
                },
                {
                  "name": "法律服务",
                  "level": 2,
                  "dws": "249",
                  "srhj": "96.9",
                  "srhjtb": "1.5",
                  "yysr": "89.9",
                  "yysrtb": "0.6",
                  "lrze": "24.4",
                  "lrzetb": "-17.5",
                  "emp": "3.4",
                  "emptb": "4.1"
                },
                {
                  "name": "咨询与调查",
                  "level": 2,
                  "dws": "1275",
                  "srhj": "376.8",
                  "srhjtb": "5.1",
                  "yysr": "373.3",
                  "yysrtb": "5.0",
                  "lrze": "4.8",
                  "lrzetb": "524.3",
                  "emp": "18.0",
                  "emptb": "-2.6"
                },
                {
                  "name": "广告业",
                  "level": 2,
                  "dws": "1086",
                  "srhj": "898.0",
                  "srhjtb": "-6.8",
                  "yysr": "898.0",
                  "yysrtb": "-6.8",
                  "lrze": "3.7",
                  "lrzetb": "312.8",
                  "emp": "5.3",
                  "emptb": "-12.6"
                },
                {
                  "name": "安全保护服务",
                  "level": 2,
                  "dws": "164",
                  "srhj": "108.3",
                  "srhjtb": "-2.9",
                  "yysr": "108.0",
                  "yysrtb": "-3.2",
                  "lrze": "-5.1",
                  "lrzetb": "-",
                  "emp": "38.0",
                  "emptb": "-3.4"
                },
                {
                  "name": "会议、展览及相关服务",
                  "level": 2,
                  "dws": "390",
                  "srhj": "55.9",
                  "srhjtb": "20.4",
                  "yysr": "52.3",
                  "yysrtb": "19.5",
                  "lrze": "-1.0",
                  "lrzetb": "-",
                  "emp": "1.7",
                  "emptb": "-6.2"
                },
                {
                  "name": "旅行社及相关服务",
                  "level": 2,
                  "dws": "117",
                  "srhj": "83.0",
                  "srhjtb": "185.9",
                  "yysr": "83.0",
                  "yysrtb": "185.9",
                  "lrze": "-2.5",
                  "lrzetb": "-",
                  "emp": "0.8",
                  "emptb": "-5.8"
                }
              ]
            },
            {
              "name": "科学研究和技术服务业",
              "level": 1,
              "dws": "3761",
              "srhj": "2186.1",
              "srhjtb": "-7.9",
              "yysr": "1916.8",
              "yysrtb": "-1.3",
              "lrze": "186.9",
              "lrzetb": "13.0",
              "emp": "63.3",
              "emptb": "0.0"
            },
            {
              "name": "水利、环境和公共设施管理业",
              "level": 1,
              "dws": "504",
              "srhj": "220.4",
              "srhjtb": "1.6",
              "yysr": "157.9",
              "yysrtb": "-0.6",
              "lrze": "1.3",
              "lrzetb": "-34.7",
              "emp": "10.6",
              "emptb": "-4.5"
            },
            {
              "name": "居民服务、修理和其他服务业",
              "level": 1,
              "dws": "673",
              "srhj": "85.6",
              "srhjtb": "12.2",
              "yysr": "80.0",
              "yysrtb": "11.1",
              "lrze": "3.7",
              "lrzetb": "-",
              "emp": "11.1",
              "emptb": "-7.7"
            },
            {
              "name": "教育",
              "level": 1,
              "dws": "1881",
              "srhj": "903.6",
              "srhjtb": "-18.0",
              "yysr": "179.7",
              "yysrtb": "-10.5",
              "lrze": "21.1",
              "lrzetb": "1027.6",
              "emp": "46.1",
              "emptb": "-6.0"
            },
            {
              "name": "卫生和社会工作",
              "level": 1,
              "dws": "892",
              "srhj": "1027.3",
              "srhjtb": "11.7",
              "yysr": "156.4",
              "yysrtb": "12.5",
              "lrze": "2.6",
              "lrzetb": "-",
              "emp": "32.0",
              "emptb": "0.9"
            },
            {
              "name": "文化、体育和娱乐业",
              "level": 1,
              "dws": "2004",
              "srhj": "605.9",
              "srhjtb": "-2.4",
              "yysr": "524.8",
              "yysrtb": "6.0",
              "lrze": "66.0",
              "lrzetb": "28.3",
              "emp": "18.4",
              "emptb": "-4.4",
              "children": [
                {
                  "name": "新闻和出版业",
                  "level": 2,
                  "dws": "500",
                  "srhj": "204.7",
                  "srhjtb": "0.6",
                  "yysr": "189.0",
                  "yysrtb": "3.7",
                  "lrze": "24.4",
                  "lrzetb": "-26.1",
                  "emp": "6.3",
                  "emptb": "-0.8"
                },
                {
                  "name": "广播、电视、电影和录音制作业",
                  "level": 2,
                  "dws": "640",
                  "srhj": "220.2",
                  "srhjtb": "0.1",
                  "yysr": "201.6",
                  "yysrtb": "3.5",
                  "lrze": "53.7",
                  "lrzetb": "25.5",
                  "emp": "5.2",
                  "emptb": "-5.4"
                },
                {
                  "name": "文化艺术业",
                  "level": 2,
                  "dws": "297",
                  "srhj": "47.7",
                  "srhjtb": "-31.3",
                  "yysr": "14.5",
                  "yysrtb": "-11.9",
                  "lrze": "-1.3",
                  "lrzetb": "-",
                  "emp": "2.9",
                  "emptb": "-1.4"
                },
                {
                  "name": "体育",
                  "level": 2,
                  "dws": "192",
                  "srhj": "37.8",
                  "srhjtb": "-23.7",
                  "yysr": "30.6",
                  "yysrtb": "11.0",
                  "lrze": "-5.0",
                  "lrzetb": "-",
                  "emp": "1.6",
                  "emptb": "-7.2"
                },
                {
                  "name": "娱乐业",
                  "level": 2,
                  "dws": "375",
                  "srhj": "95.4",
                  "srhjtb": "22.4",
                  "yysr": "89.2",
                  "yysrtb": "20.2",
                  "lrze": "-5.7",
                  "lrzetb": "-",
                  "emp": "2.4",
                  "emptb": "-12.3"
                }
              ]
            }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
