<template>
  <div class="page-content">
    <div style="background-color: #FFFFFF;padding: 20px;margin-top: 25px;" v-if="data">
      <el-row :gutter="30">
        <el-col :span="5">
          <div class="group-nav-button" v-for="g in groups" :key="g.name" @click="selectGroup(g)"
               :class="{selected:g.selected}">{{g.name}}</div>
        </el-col>
        <el-col :span="19">
          <div class="info-list">
            <div v-for="item in data.content" :key="item.data.urlMd5" class="info-list-item">
              <div class="info-list-item-dot"></div>
              <div class="info-list-field">
                <a class="link" :href="item.data.url" target="_blank">{{item.data.title}}</a>
              </div>
              <div class="info-list-field info-list-field-fixed" style="width: 100px;">{{item.data.publishDate}}</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-pagination layout="prev, pager, next" background :page-size="pageSize"
                     :total="data.totalElements" :current-page="data.number+1"
                     @current-change="loadData">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "../api";

export default {
  name: "TjjNewsList",
  data(){
    return {
      groups:[
        {
          name:'年度公报',
          selected:true
        },
        {
          name:'经普公报',
          selected:false
        },
        {
          name:'农普公报',
          selected:false
        },
        {
          name:'人普公报',
          selected:false
        },
        {
          name:'生态公报',
          selected:false
        },
        {
          name:'其他公报',
          selected:false
        }
      ],
      pageSize:20,
      data:null
    }
  },
  created() {
    this.loadData(1)
  },
  methods:{
    async loadData(page){
      this.data=(await api.listTjjNews({groupname:this.groups.find(i=>i.selected).name,page:page-1,size:this.pageSize})).data
    },
    selectGroup(g){
      for(const i of this.groups){
        i.selected=(i.name===g.name)
      }
      this.loadData(1)
    }
  }
}
</script>

<style scoped>

</style>
